<template>
  <Layout :footer-style="1">
    <div class="container">
      <Breadcrumb root-url="/hardware-product" parent-url="硬件产品" current-url="服务器" style="border: none;"/>
    </div>

    <div class="rn-service-area rn-section-gapBottom pt--100 bg-color-light area-2">
      <div class="container">
        <div class="product">
          <img class="pic" src="@/assets/images/product/n2.png" />
          <div class="about">
            <div class="title">服务器</div>
            <div class="content">
              是计算机的一种，它比普通计算机运行更快、负载更高、价格更贵。服务器在网络中为其它客户机（如PC机、智能手机、ATM等终端甚至是火车系统等大型设备）提供计算或者应用服务。服务器具有高速的CPU运算能力、长时间的可靠运行、强大的I/O外部数据吞吐能力以及更好的扩展性。            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Start Service Area -->
    <div class="rn-service-area rn-section-gapBottom pt--60 area-2">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="section-title" data-aos="fade-up">
              <h3 class="title color-gray">戴尔服务器</h3>
            </div>
          </div>
          <div class="col-lg-12 text-center">
            <img class="company-logo" style="height: 128px;width: 128px;margin: 48px 0" src="@/assets/images/brand/brand-19.png">
            <div class="company-info">
              是一家总部位于美国德克萨斯州朗德罗克的世界五百强企业，由迈克尔·戴尔于1984年创立。戴尔以生产、设计、销售家用以及办公室电脑而闻名，不过它同时也涉足高端电脑市场，生产与销售服务器、数据储存设备、网络设备等。
            </div>
          </div>
        </div>

        <Separator style="margin: 80px 0;"/>

        <div class="row">
          <div class="col-lg-12">
            <div class="section-title" data-aos="fade-up">
              <h3 class="title color-gray">联想服务器</h3>
            </div>
          </div>
          <div class="col-lg-12 text-center">
            <img class="company-logo" src="@/assets/images/brand/brand-20.png">
            <div class="company-info">
              是一家成立于中国、业务遍及180个市场的全球化科技公司。联想聚焦全球化发展，持续开发创新技术，致力于建设一个更加包容、值得信赖和可持续发展的数字化社会，引领和赋能智能化新时代的转型变革，为全球数以亿计的消费者打造更好的体验和机遇。 联想作为全球领先ICT科技企业，秉承“智能，为每一个可能”的理念，联想持续研究、设计与制造全球最完备的端到端智能设备与智能基础架构产品组合，为用户与全行业提供整合了应用、服务和最佳体验的智能终端，以及强大的云基础设施与行业智能解决方案。            </div>
          </div>
        </div>

        <Separator style="margin: 80px 0;"/>

        <div class="row">
          <div class="col-lg-12">
            <div class="section-title" data-aos="fade-up">
              <h3 class="title color-gray">浪潮服务器</h3>
            </div>
          </div>
          <div class="col-lg-12 text-center">
            <img class="company-logo" src="@/assets/images/brand/brand-06.png">
            <div class="company-info">
              是中国本土顶尖的大型IT企业之一，中国领先的云计算、大数据服务商( [1])。浪潮集团旗下拥有浪潮信息、浪潮软件、浪潮国际三家上市公司，业务涵盖云计算、大数据、工业互联网等新一代信息技术产业领域，为全球120多个国家和地区提供IT产品和服务，浪潮致力于成为世界一流的新一代信息技术产业龙头企业，经济社会数字化转型的优秀服务商，新型基础设施建设的骨干企业。            </div>
          </div>
        </div>

        <Separator style="margin: 80px 0;"/>

        <div class="row">
          <div class="col-lg-12">
            <div class="section-title" data-aos="fade-up">
              <h3 class="title color-gray">新华三服务器</h3>
            </div>
          </div>
          <div class="col-lg-12 text-center">
            <img class="company-logo" src="@/assets/images/brand/brand-15.png">
            <div class="company-info">
              新华三集团（H3C）作为数字化解决方案领导者，致力于成为客户业务创新、数字化转型值得信赖的合作伙伴。作为紫光集团旗下的核心企业，新华三通过深度布局“芯-云-网-边-端”全产业链，不断提升数字化和智能化赋能水平。新华三拥有芯片、计算、存储、网络、5G、安全、终端等全方位的数字化基础设施整体能力，提供云计算、大数据、人工智能、工业互联网、信息安全、智能联接、边缘计算等在内的一站式数字化解决方案，以及端到端的技术服务。同时，新华三也是HPE服务器、存储和技术服务的中国独家提供商。
            </div>
          </div>
        </div>

      </div>
    </div>
    <!-- End Service Area -->
  </Layout>
</template>

<script>
import Layout from '@/components/common/Layout'
import Icon from '@/components/icon/Icon'
import Separator from '@/components/elements/separator/Separator'
import SectionTitle from '@/components/elements/sectionTitle/SectionTitle'
import Breadcrumb from '@/components/elements/breadcrumb/Breadcrumb'
export default {
  name: 'Product',
  components: {
    SectionTitle,
    Separator,
    Icon,
    Breadcrumb,
    Layout
  },
  data() {
    return {
    }
  }
}
</script>
<style lang="scss" scoped>
.display-block{
  display: block;
}
.area-2{
  .icon {
    .p-img {
      width: 64px;
      height: 64px;
    }
  }
  .more{
    display: inline-flex;
    color: #2261AA;
    img {
      width: 27px;
      height: 27px;
    }
    .i1{
      margin-left: -4px;
    }
    .i2{
      margin-left: -20px;
    }
  }
}
.product{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  .pic{
    height: 220px;
  }
  .about{
    margin-left: 40px;
    color: #000000;
    .title{
      font-size: 32px;
      font-weight: bold;
    }
    .content{
      margin-top: 12px;
      font-size: 20px;
    }
  }
}
.company-logo{
  width: 320px;
}
.company-info{
  font-size: 24px;
  color: #000000;
  text-align: left;
  margin-top: 20px;
}
</style>
